// orgs
export const ROOT = 'ROOT';

// authentication
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const CHECK_LOGOUT_STATE = 'CHECK_LOGOUT_STATE';

export const OAUTH_LOGIN_URL = 'OAUTH_LOGIN_URL';
export const OAUTH_LOGOUT_URL = 'OAUTH_LOGOUT_URL';
export const OAUTH_TOKEN = 'OAUTH_TOKEN';
export const OAUTH_LOGIN_SUCCESS = 'OAUTH_LOGIN_SUCCESS';
export const OAUTH_REGISTER = 'OAUTH_REGISTER';
export const OAUTH_LOGIN = 'OAUTH_LOGIN';

export const ERROR_LOGOUT = 'ERROR_LOGOUT';

// user
export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const LOAD_ACCOUNT_PREFERENCES = 'LOAD_ACCOUNT_PREFERENCES';
export const LOAD_USER_ACCOUNTS = 'LOAD_USER_ACCOUNTS';
export const LOAD_RECENT_PROJECTS = 'LOAD_RECENT_PROJECTS';
export const UPDATE_USER_MODAL_OPEN = 'UPDATE_USER_MODAL_OPEN';

// Subscription
export const LOAD_SUBSCRIPTION_STATE = 'LOAD_SUBSCRIPTION_STATE';
export const REFRESH_STATE = 'REFRESH_STATE';
export const LOAD_RELATED_COLLECTIONS_STATE = 'LOAD_RELATED_COLLECTIONS_STATE';

// project
export const LIST_PROJECTS = 'LIST_PROJECTS';
export const LOAD_PROJECT = 'LOAD_PROJECT';
export const LOAD_PROJECT_FILE_GROUP = 'LOAD_PROJECT_FILE_GROUP';
export const PIN_PROJECT = 'PIN_PROJECT';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const SELECTED_PROJECT_PERMISSIONS = 'SELECTED_PROJECT_PERMISSIONS';
export const MAP_VIEW_STATE = 'MAP_VIEW_STATE';
export const LOAD_OVERVIEW_COLLARS_WITH_COUNT = 'LOAD_OVERVIEW_COLLARS_WITH_COUNT';
export const CLEAR_OVERVIEW_COLLARS = 'CLEAR_OVERVIEW_COLLARS';
export const CLEAR_PROJECT_LOAD_STATE = 'CLEAR_PROJECT_LOAD_STATE';
export const CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE =
    'CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE';

// job
export const SET_JOB_SEARCH_TERM = 'SET_JOB_SEARCH_TERM';
export const LIST_JOBS = 'LIST_JOBS';
export const CLEAR_JOB_LIST = 'CLEAR_JOB_LIST';
export const CREATE_OR_UPDATE_JOB = 'CREATE_OR_UPDATE_JOB';
export const LOAD_EVENTS = 'LOAD_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const LOAD_POSTS = 'LOAD_POSTS';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const DELETE_POSTS = 'DELETE_POSTS';
export const CLEAR_EVENT_ACTIONS = 'CLEAR_EVENT_ACTIONS';
export const CLEAR_JOBS_SECTION = 'CLEAR_JOBS_SECTION';
export const DOWNLOAD_POST_ATTACHMENT = 'DOWNLOAD_POST_ATTACHMENT';

// configuration
export const EDIT_CONFIGURATION_HEADER_FIELD_TYPES = 'EDIT_CONFIGURATION_HEADER_FIELD_TYPES';
export const ADD_CONFIGURATION_HEADER_FIELD_TYPES = 'ADD_CONFIGURATION_HEADER_FIELD_TYPES';
export const LOAD_CONFIGURATION_HEADER_FIELD_TYPES = 'LOAD_CONFIGURATION_HEADER_FIELD_TYPES';
export const LOAD_CONFIGURATION_HEADERS = 'LOAD_CONFIGURATION_HEADERS';
export const LOAD_CONFIGURATION_LISTS = 'LIST_CONFIGURATION_LISTS';
export const LOAD_CONFIGURATION_TABLES = 'LIST_CONFIGURATION_TABLES';
export const LOAD_CONFIGURATION_ACTIVITIES = 'LOAD_CONFIGURATION_ACTIVITIES';
export const LOAD_CONFIGURATION_GRIDS = 'LOAD_CONFIGURATION_GRIDS';
export const LOAD_CONFIGURATION_PROJECTS = 'LOAD_CONFIGURATION_PROJECTS';
export const LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS = 'LOAD_POSSIBLE_SAMPLE_RESULTS_COLUMNS';
export const LOAD_CONFIGURATION_RANKED_COLUMNS = 'LOAD_CONFIGURATION_RANKED_COLUMNS';
export const LOAD_CONFIGURATION_SAMPLE_WORKFLOWS = 'LOAD_CONFIGURATION_SAMPLE_WORKFLOWS';
export const LOAD_CONFIGURATION_USERS = 'LOAD_CONFIGURATION_USERS';
export const LOAD_CONFIGURATION_CATEGORIES = 'LOAD_CONFIGURATION_CATEGORIES';
export const CONFIGURATION_CATEGORIES_OPERATION = 'CONFIGURATION_CATEGORIES_OPERATION';
export const MODIFY_LIST_FILE_GROUP = 'MODIFY_LIST_FILE_GROUP';
export const CLEAR_LIST_FILE_STATE = 'CLEAR_LIST_FILE_STATE';
export const EMPTY_LIST_FILE_GROUPS = 'EMPTY_LIST_FILE_GROUPS';
export const MODIFY_LIST_FILES = 'MODIFY_LIST_FILES';
export const CLEAR_RANKED_COLUMNS_STATE = 'CLEAR_RANKED_COLUMNS_STATE';
export const CLEAR_POSSIBLE_COLUMNS = 'CLEAR_POSSIBLE_COLUMNS';
export const ADD_SAMPLE_RESULTS_RANKED_COLUMN = 'ADD_SAMPLE_RESULTS_RANKED_COLUMN';
export const EDIT_RANKED_COLUMN = 'EDIT_RANKED_COLUMN';
export const DELETE_RANKED_COLUMN = 'DELETE_RANKED_COLUMN';
export const CLEAR_HEADER_FIELD_TYPES_DATA_ITEMS = 'CLEAR_HEADER_FIELD_TYPES_DATA_ITEMS';
export const CLEAR_HEADER_FIELD_TYPES_DELETE_STATE = 'CLEAR_HEADER_FIELD_TYPES_DELETE_STATE';
export const CLEAR_HEADER_FIELD_TYPES_ADD_STATE = 'CLEAR_HEADER_FIELD_TYPES_ADD_STATE';
export const CLEAR_HEADERS_DATA_ITEMS = 'CLEAR_HEADERS_DATA_ITEMS';
export const CLEAR_TABLES_ITEMS = 'CLEAR_DATA_ITEMS';
export const CLEAR_LISTS_DATA_ITEMS = 'CLEAR_LISTS_DATA_ITEMS';
export const CLEAR_GRIDS_DATA_ITEMS = 'CLEAR_GRIDS_DATA_ITEMS';
export const CLEAR_ACTIVITIES_DATA_ITEMS = 'CLEAR_ACTIVITIES_DATA_ITEMS';
export const CLEAR_PROJECTS_DATA_ITEMS = 'CLEAR_PROJECTS_DATA_ITEMS';
export const CLEAR_ACTIVITY_GROUPS_DATA_ITEMS = 'CLEAR_ACTIVITY_GROUPS_DATA_ITEMS';
export const CLEAR_CONFIGURATION_SECTION = 'CLEAR_CONFIGURATION_SECTION';
export const CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_STATE =
    'CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_STATE';
export const CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_ADD_STATE =
    'CLEAR_CONFIGURATION_SAMPLE_WORKFLOW_ADD_STATE';
export const MODIFY_CONFIGURATION_DATA_GRID_STATE = 'MODIFY_CONFIGURATION_DATA_GRID_STATE';
export const MODIFY_COLUMN = 'MODIFY_COLUMN';
export const CLEAR_MODIFY_COLUMN = 'CLEAR_MODIFY_COLUMN';
export const UNSELECT_CONFIGURATION_ITEMS = 'UNSELECT_CONFIGURATION_ITEMS';
export const SELECT_CONFIGURATION_ITEMS = 'SELECT_CONFIGURATION_ITEMS';
export const CLEAR_ALL_CONFIGURATION_ITEMS = 'CLEAR_ALL_CONFIGURATION_ITEMS';
export const SELECT_ALL_CONFIGURATION_ITEMS = 'SELECT_ALL_CONFIGURATION_ITEMS';
export const TOTAL_CONFIGURATION_ITEMS = 'TOTAL_CONFIGURATION_ITEMS';

export const DELETE_CONFIGURATION_LIST = 'DELETE_CONFIGURATION_LIST';
export const CLONE_CONFIGURATION_LIST = 'CLONE_CONFIGURATION_LIST';
export const DELETE_CONFIGURATION_HEADER = 'DELETE_CONFIGURATION_HEADER';
export const DELETE_CONFIGURATION_FIELD = 'DELETE_CONFIGURATION_FIELD';

export const CONVERT_BOUNDARY_TO_DECIMAL = 'CONVERT_BOUNDARY_TO_DECIMAL';
export const CONVERT_PROJECT_BOUNDARIES = 'CONVERT_PROJECT_BOUNDARIES';
export const EDIT_CONFIGURATION_PROJECT = 'EDIT_CONFIGURATION_PROJECT';
export const ADD_CONFIGURATION_PROJECT = 'ADD_CONFIGURATION_PROJECT';
export const LOAD_USER_PERMISSIONS = 'LOAD_USER_PERMISSIONS';
export const LOAD_DEFAULT_LAB_SERVICES = 'LOAD_DEFAULT_LAB_SERVICES';
export const LOAD_DEFAULT_PROJECTIONS = 'LOAD_DEFAULT_PROJECTIONS';
export const CLONE_CONFIGURATION_PROJECT = 'CLONE_CONFIGURATION_PROJECT';
export const DELETE_CONFIGURATION_PROJECT = 'DELETE_CONFIGURATION_PROJECT';
export const ADD_CONFIGURATION_HEADER = 'ADD_CONFIGURATION_HEADER';
export const DELETE_CONFIGURATION_TABLEVIEW = 'DELETE_CONFIGURATION_TABLEVIEW';
export const DELETE_CONFIGURATION_ACTIVITY = 'DELETE_CONFIGURATION_ACTIVITY';
export const CLONE_CONFIGURATION_ACTIVITY = 'CLONE_CONFIGURATION_ACTIVITY';
export const ADD_CONFIGURATION_ACTIVITY = 'ADD_CONFIGURATION_ACTIVITY';
export const ADD_CONFIGURATION_SAMPLE_WORKFLOW = 'ADD_CONFIGURATION_SAMPLE_WORKFLOW';
export const EDIT_CONFIGURATION_SAMPLE_WORKFLOW = 'EDIT_CONFIGURATION_SAMPLE_WORKFLOW';
export const DELETE_CONFIGURATION_SAMPLE_WORKFLOW = 'DELETE_CONFIGURATION_SAMPLE_WORKFLOW';
export const CLONE_CONFIGURATION_SAMPLE_WORKFLOW = 'CLONE_CONFIGURATION_SAMPLE_WORKFLOW';
export const CLEAR_CONFIGURATION_OPS_STATE = 'CLEAR_CONFIGURATION_OPS_STATE';
export const ADD_CONFIGURATION_LIST = 'ADD_CONFIGURATION_LIST';
export const ADD_CONFIGURATION_TABLE = 'ADD_CONFIGURATION_TABLE';
export const EDIT_CONFIGURATION_LIST = 'EDIT_CONFIGURATION_LIST';
export const EDIT_CONFIGURATION_TABLE = 'EDIT_CONFIGURATION_TABLE';
export const EDIT_CONFIGURATION_HEADER = 'EDIT_CONFIGURATION_HEADER';
export const EDIT_CONFIGURATION_ACTIVITY = 'EDIT_CONFIGURATION_ACTIVITY';
export const ADD_HEADER_FIELD = 'ADD_HEADER_FIELD';
export const MODIFY_SAMPLE_WORKFLOWS = 'MODIFY_SAMPLE_WORKFLOWS';
export const ADD_CONFIGURATION_PROJECT_ACTIVITY = 'ADD_CONFIGURATION_PROJECT_ACTIVITY';
export const EDIT_CONFIGURATION_PROJECT_ACTIVITIES = 'EDIT_CONFIGURATION_PROJECT_ACTIVITIES';
export const DELETE_CONFIGURATION_PROJECT_ACTIVITY = 'DELETE_CONFIGURATION_PROJECT_ACTIVITY';
export const ASSIGN_UNASSIGN_USERS = 'ASSIGN_UNASSIGN_USERS';
export const ASSIGN_USER_TO_PROJECT_ACTIVITY_GROUP = 'ASSIGN_USER_TO_PROJECT_ACTIVITY_GROUP';
export const LOAD_CONFIGURATION_ACTIVITY_GROUPS = 'LOAD_CONFIGURATION_ACTIVITY_GROUPS';
export const ADD_CONFIGURATION_GRID = 'ADD_CONFIGURATION_GRID';
export const EDIT_CONFIGURATION_GRID = 'EDIT_CONFIGURATION_GRID';
export const DELETE_CONFIGURATION_GRID = 'DELETE_CONFIGURATION_GRID';
export const LOAD_GRID_PREVIEW = 'LOAD_GRID_PREVIEW';
export const UPDATE_GRID_NAME = 'UPDATE_GRID_NAME';
export const OP_CONFIGURATION_USERS = 'OP_CONFIGURATION_USERS';
export const ADD_EDIT_DELETE_ACTIVITY_GROUPS = 'ADD_EDIT_DELETE_ACTIVITY_GROUPS';
export const RELOAD_CONFIGURATION_ITEMS = 'RELOAD_CONFIGURATION_ITEMS';

export const GET_TOKEN = 'GET_TOKEN';
export const LIST_MESSAGES = 'LIST_MESSAGES';
export const POLL_MESSAGES = 'POLL_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const BULK_READ_MESSAGES = 'BULK_READ_MESSAGES';
export const BULK_UNREAD_MESSAGES = 'BULK_UNREAD_MESSAGES';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const BULK_DELETE_MESSAGES = 'BULK_DELETE_MESSAGES';

export const CREATE_FILTERS = 'CREATE_FILTERS';
export const CREATE_RESOURCES = 'CREATE_RESOURCES';
export const DELETE_FILTERS = 'DELETE_FILTERS';
export const DELETE_RESOURCES = 'DELETE_RESOURCES';
export const LOAD_FILTERS = 'LOAD_FILTERS';
export const LOAD_RESOURCES = 'LOAD_RESOURCES';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const LOAD_DIGEST = 'LOAD_DIGEST';
export const UPDATE_DIGEST = 'UPDATE_DIGEST';

// snackbar notifications
export const ADD_SNACKBAR_NOTIFICATION = 'ADD_SNACKBAR_NOTIFICATION';
export const DELETE_SNACKBAR_NOTIFICATION = 'DELETE_SNACKBAR_NOTIFICATION';
export const CLEAR_SNACKBAR_NOTIFICATIONS = 'CLEAR_SNACKBAR_NOTIFICATIONS';
export const SELECT_ROW_COL_IN_GRID = 'SELECT_ROW_COL_IN_GRID';

// drillholes
export const LOAD_DRILLHOLES = 'LOAD_DRILLHOLES';
export const LOAD_DRILLHOLES_WITH_COUNT = 'LOAD_DRILLHOLES_WITH_COUNT';

export const SAVE_DRILLHOLE = 'SAVE_DRILLHOLE';
export const SELECT_ALL_COLLARS = 'SELECT_ALL_COLLARS';
export const CLEAR_ALL_COLLARS = 'CLEAR_ALL_COLLARS';
export const UNSELECT_COLLARS = 'UNSELECT_COLLARS';
export const SELECT_COLLARS = 'SELECT_COLLARS';
export const LOAD_DRILLHOLE = 'LOAD_DRILLHOLE';
export const LOAD_SAMPLES = 'LOAD_SAMPLES';
export const LOAD_SAMPLE_RESULTS = 'LOAD_SAMPLE_RESULTS';
export const LOAD_RANKED_COLUMNS = 'LOAD_RANKED_COLUMNS';
export const LOAD_SAMPLE = 'LOAD_SAMPLE';
export const LOAD_LITHOLOGY_ROW_REFERENCES = 'LOAD_LITHOLOGY_ROW_REFERENCES';
export const LOAD_ROW_REFERENCES = 'LOAD_ROW_REFERENCES';
export const LOAD_SAMPLE_ROW_REFERENCES = 'LOAD_SAMPLE_ROW_REFERENCES';
export const SAVE_ROW_REFERENCES = 'SAVE_ROW_REFERENCES';
export const SAVE_SPLIT_ROW_ROW_REFERENCES = 'SAVE_SPLIT_ROW_ROW_REFERENCES';
export const MERGE_ROW_REFERENCES = 'MERGE_ROW_REFERENCES';
export const TOGGLE_SPAN = 'TOGGLE_SPAN';
export const APPLY_EXTERNAL_FILTER = 'APPLY_EXTERNAL_FILTER';
export const FILTER_APPLIED = 'FILTER_APPLIED';

export const DELETE_ROW_REFERENCES = 'DELETE_ROW_REFERENCES';
export const FILL_DOWN_SAVE_ROW_REFERENCES = 'FILL_DOWN_SAVE_ROW_REFERENCES';
export const COPY_INTERVALS = 'COPY_INTERVALS';
export const COPY_CELLS = 'COPY_CELLS';
export const COPY_COORDINATE_CELLS = 'COPY_COORDINATE_CELLS';
export const CLEAR_ROW_REFERENCE_RESPONSE = 'CLEAR_ROW_REFERENCE_RESPONSE';
export const SET_CURRENT_DRILLHOLE = 'SET_CURRENT_DRILLHOLE';
export const ADD_DATA_FILE_GROUP = 'ADD_DATA_FILE_GROUP';
export const GET_FILES_FOR_GROUP = 'GET_FILES_FOR_GROUP';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const IMPORT_LIST = 'IMPORT_LIST';
export const CONFIRM_COLUMN_TYPES = 'CONFIRM_COLUMN_TYPES';
export const DELETE_IMPORTED_LIST = 'DELETE_IMPORTED_LIST';
export const EXPORT_CONFIGURATION = 'EXPORT_CONFIGURATION';
export const CLEAR_IMPORT_LIST_STATE = 'CLEAR_IMPORT_LIST_STATE';
export const CLEAR_EXPORT_STATE = 'CLEAR_EXPORT_STATE';
export const DELETE_FILE = 'DELETE_FILE';
export const CLEAR_FILES_STATE = 'CLEAR_FILES_STATE';
export const CLEAR_FILES_FOR_GROUP_LENGTHS_STATE = 'CLEAR_FILES_FOR_GROUP_LENGTHS_STATE';
export const CLEAR_SAVE_STATE = 'CLEAR_SAVE_STATE';
export const CLEAR_DELETE_DRILL_HOLE_STATE = 'CLEAR_DELETE_DRILL_HOLE_STATE';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const CLEAR_DOWNLOADED_FILE = 'CLEAR_DOWNLOADED_FILE';
export const CLEAR_EXPORT_COLLAR_STATE = 'CLEAR_EXPORT_COLLAR_STATE';
export const DUMP_TABLE_DATA = 'DUMP_TABLE_DATA';
export const CLEAR_DUMP_TABLE_DATA = 'CLEAR_DUMP_TABLE_DATA';
export const CREATE_ROWS = 'CREATE_ROWS';
export const ADD_DRILLHOLE = 'ADD_DRILLHOLE';
export const CLONE_DRILHOLE = 'CLONE_DRILLHOLE';
export const EXPORT_COLLAR = 'EXPORT_COLLAR';
export const SELECT_COLLARS_BY_ID = 'SELECT_COLLARS_BY_ID';
export const IMPORT_TABLE = 'IMPORT_TABLE';
export const CLEAR_IMPORT_TABLE_STATE = 'CLEAR_IMPORT_TABLE_STATE';
export const SET_COLLAR_SEARCH_TERM = 'SET_COLLAR_SEARCH_TERM';
// =================================================================
export const CUSTOM_EXPORT = 'CUSTOM_EXPORT';
export const GET_EXPORT_TEMPLATES = 'GET_EXPORT_TEMPLATES';
export const GET_CUSTOM_EXPORTS = 'GET_CUSTOM_EXPORTS';

// =================================================================

export const CLEAR_ADD_DRILL_HOLE_STATE = 'CLEAR_ADD_DRILL_HOLE_STATE';
export const CLEAR_CLONE_DRILL_HOLE_STATE = 'CLEAR_CLONE_DRILL_HOLE_STATE';
export const RE_INDEX = 'RE_INDEX';
export const DELETE_DRILLHOLE = 'DELETE_DRILLHOLE';
export const LOAD_EMPTY_FILE_GROUPS = 'LOAD_EMPTY_FILE_GROUPS';
export const CLEAR_EMPTY_FILE_GROUPS = 'CLEAR_EMPTY_FILE_GROUPS';
export const LOAD_LAB_CERTIFICATE_INFO = 'LOAD_LAB_CERTIFICATE_INFO';
export const CLEAR_SAVE_ROW_REFERENCE_STATE = 'CLEAR_SAVE_ROW_REFERENCE_STATE';
export const UNASSIGN_USER_FROM_DRILLHOLE = 'UNASSIGN_USER_FROM_DRILLHOLE';
export const CLEAR_UNASSIGN_USER_FROM_DHOLE_STATE = 'CLEAR_UNASSIGN_USER_FROM_DHOLE_STATE';
export const LOAD_COORDINATES_TABLE = 'LOAD_COORDINATES_TABLE';

// account
export const SWITCH_SUBSCRIPTION = 'SWITCH_SUBSCRIPTION';
export const SWITCH_DEFAULT_SUBSCRIPTION = 'SWITCH_DEFAULT_SUBSCRIPTION';
export const LOAD_ACCOUNT_INFORMATION = 'LOAD_ACCOUNT_INFORMATION';
export const LOAD_ACCOUNT_ACTIVITIES = 'LOAD_ACCOUNT_ACTIVITIES';
export const LOAD_ACCOUNT_ACTIVITY = 'LOAD_ACCOUNT_ACTIVITY';
export const LOAD_TABLE_VIEWS = 'LOAD_TABLE_VIEWS';
export const LOAD_TABLES = 'LOAD_TABLES';
export const LOAD_TABLE_CATEGORIES = 'LOAD_TABLE_CATEGORIES';
export const LOAD_LISTS = 'LOAD_LISTS';

export const LOAD_LIST_WITH_ID = 'LOAD_LIST_WITH_ID';
export const LOAD_LIST_CATEGORIES = 'LOAD_LIST_CATEGORIES';
export const LOAD_HEADERS = 'LOAD_HEADERS';
export const LOAD_HEADER_CATEGORIES = 'LOAD_HEADER_CATEGORIES';
export const LOAD_HEADER_TYPES = 'LOAD_HEADER_TYPES';
export const LOAD_SYSTEM_FILTERS = 'LOAD_SYSTEM_FILTERS';
export const LOAD_FILTER_OPERATORS = 'LOAD_FILTER_OPERATORS';
export const LOAD_DEFAULT_PERMISSIONS = 'LOAD_DEFAULT_PERMISSIONS';
export const LOAD_COORDINATES = 'LOAD_COORDINATES';
export const LOAD_ACTIVITY_GROUPS = 'LOAD_ACTIVITY_GROUPS';
export const LOAD_ACTIVITY_CATEGORIES = 'LOAD_ACTIVITY_CATEGORIES';
export const LOAD_VALIDATION_RULE_CATEGORIES = 'LOAD_VALIDATION_RULE_CATEGORIES';
export const LOAD_COLOURS = 'LOAD_COLOURS';
export const LOAD_SAMPLE_RESULTS_RANKED_UNITS = 'LOAD_SAMPLE_RESULTS_RANKED_UNITS';
export const LOAD_SAMPLE_DISPATCH_HEADERS = 'LOAD_SAMPLE_DISPATCH_HEADERS';
export const LOAD_SAMPLE_WORKFLOWS = 'LOAD_SAMPLE_WORKFLOWS';
export const CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE =
    'CREATE_UPDATE_DELETE_COLLECTION_ITEM_SUBSCRIPTION_STATE';
export const CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE =
    'CREATE_UPDATE_DELETE_NESTED_ITEM_SUBSCRIPTION_STATE';
export const CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE =
    'CREATE_UPDATE_DELETE_KEY_IN_COLLECTION_ITEM_SUBSCRIPTION_STATE';
export const CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE =
    'CREATE_UPDATE_DELETE_COLLECTION_ITEM_LIST_SUBSCRIPTION_STATE';

// map
export const LOAD_MAP_TOKEN = 'LOAD_MAP_TOKEN';

// integrations
export const IMAGO_LOGIN = 'IMAGO_LOGIN';
export const IMAGO_CHECK_CONNECTION = 'IMAGO_CHECK_CONNECTION';
export const IMAGO_SET_CONNECTION_STATUS = 'IMAGO_SET_CONNECTION_STATUS';
export const IMAGO_DISCONNECT = 'IMAGO_DISCONNECT';
export const IMAGO_USER_CONTEXT = 'IMAGO_USER_CONTEXT';
export const SEEQUENT_CLOUD_CONFIGURATION = 'SEEQUENT_CLOUD_CONFIGURATION';
export const LOAD_INTEGRATIONS = 'LOAD_INTEGRATIONS';
export const IMAGO_SYNC = 'IMAGO_SYNC';
export const CLEAR_IMAGO_USER_CONTEXT = 'CLEAR_IMAGO_USER_CONTEXT';
export const CLEAR_IMAGO_LOGIN_STATE = 'CLEAR_IMAGO_LOGIN_STATE';
export const LOAD_EVO_WORKSPACES = 'LOAD_EVO_WORKSPACES';
export const LOAD_EVO_ORG = 'LOAD_EVO_ORG';
export const INTEGRATION_FAIL_STATE = 'INTEGRATION_FAIL_STATE';
export const INTEGRATION_PENDING_STATE = 'INTEGRATION_PENDING_STATE';
export const EVO_CONFIGURATION = 'EVO_CONFIGURATION';
export const EVO_DISCOVERY = 'EVO_DISCOVERY';
export const LOAD_DOWNHOLE_COLLECTIONS = 'LOAD_DOWNHOLE_COLLECTIONS';
export const CHECK_EVO_PERMISSIONS = 'CHECK_EVO_PERMISSIONS';
